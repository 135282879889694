/********************************
 * MDP(마이데이터) API 스크립트
 ********************************/
import { callMdpApi } from '~/api/mdp';

/**
 * 보험- 기본정보 조회
 * @returns
 */
export const astsBasInf = async () => {
  return await callMdpApi('/is/insr-bas-inf', {}, false);
};

/**
 * 보험- 고객별 자산 개인화
 * @returns
 */
export const astsPsnz = async data => {
  return await callMdpApi('/is/asts-psnz', data, false);
};

/**
 * 보험 - 가입한 보험
 * @returns
 */
export const insrInf = () => {
  return callMdpApi('/is/insr-list', {}, false);
};

/**
 * 보험 - 보장이 끝난 보험
 * @returns
 */
export const insrGrnEndInf = data => {
  return callMdpApi('/is/insr-grnEnd-list', data, false);
};

/**
 * 보험 - 보험내역
 * @returns
 */
export const insrIz = data => {
  return callMdpApi('/is/insr-iz', data, false);
};

/**
 * 보험 - 보험관리
 * @returns
 */
export const insrMgmt = data => {
  return callMdpApi('/is/insr-mgmt', data, false);
};

/**
 * 보험 - 납입내역 상세
 * @returns
 */
export const insrPyDtl = data => {
  return callMdpApi('/is/insr-py-dtl', data, false);
};

/**
 * 보험 - 보장리포트 내보장 내역
 * @returns
 */
export const insrGrnInf = () => {
  return callMdpApi('/is/insr-grn-list', {}, false);
};

/**
 * 보험 - 보험명 변경
 * @returns
 */
export const insrNcknmCh = data => {
  return callMdpApi('/is/insr-ncknm-ch', data, false);
};

/**
 * 보험 - 보험내역 - 자동차보험
 * @returns
 */
export const insrIzCarCollect = data => {
  return callMdpApi('/is/insr-iz-car-collect', data, false);
};

/**
 * 보험(고도화) - 기본정보 조회
 * @returns
 */
export const insBaseInfo = async () => {
  return await callMdpApi('/is/insr-base-inf', {}, false);
};

/**
 * 보험(고도화) - 보험료 정보 조회
 * @returns
 */
export const insFeeInfo = async param => {
  return await callMdpApi('/is/insr-fee-inf', param, false);
};

/**
 * 보험(고도화) - 보장정보 조회
 * @returns
 */
export const insGuaranteeInfo = async param => {
  return await callMdpApi('/is/insr-grn-inf', param, false);
};
/**
 * 보험(고도화) - 생애주기별 보험 조회(미래이벤트)
 * @returns
 */
export const insLifeTmInfo = async param => {
  return await callMdpApi('/is/insr-lftm-prd', param, false);
};
/**
 * 보험(고도화) - 보험 가입 비중
 * @returns
 */
export const insJoinWeitInfo = async param => {
  return await callMdpApi('/is/insr-join-weit', param, false);
};

/**
 * 보험(고도화) - 자동차보험 평균 보험료 계산(또래)
 * @returns
 */
export const insCarInfo = async param => {
  return await callMdpApi('/is/car-insr-av-fee', param, false);
};
