/********************************
 * MDP(마이데이터) API 스크립트
 ********************************/
import { callMdpApi } from '~/api/mdp';
import { callApi } from '~/api/pfm';

// 메인 통합자산 조회 API
export const mainInquiry = async () => {
  return await callMdpApi({ url: '/mai/maiAsts', progress: false }, {}, true);
};

// 메인 순서변경 설정값 조회 API
export const mainBzgrp = async () => {
  return await callMdpApi('/mai/bzgrp', {}, true);
};

// 메인 순서변경 설정 API
export const mainAstsOdr = async data => {
  return await callMdpApi('/mai/asts-odr', data, true);
};

// 메인 금액 보기 설정 API
export const mainAmBrwsShow = async data => {
  return await callMdpApi('/mai/am-brws-show', data, true);
};

// 메인 금액 안보기 설정 API
export const mainAmBrwsHid = async data => {
  return await callMdpApi('/mai/am-brws-hid', data, true);
};

// 오픈뱅킹코드 매핑 기관정보 조회 API
export const getOpbkOrgInfo = async data => {
  return await callMdpApi('/asset-mgmt/opbk-org-info', data, true);
};

/**
 * (Mydata) 대표계좌1건조회
 * @param {String} data 요청DATA
 * @returns
 */
export const selDgAcnoDtlMydata = async data => {
  return await callApi('/restapi/mny/base/selDgAcnoDtl', data, true);
};

/**
 * (Mydata) 등록계좌 조회 selListRgAc API
 * @param {Object} data 요청 data
 * @returns selListRcmdAc
 */
export const selListRgAcMydata = async data => {
  return await callApi('/restapi/opb/assetremt/selListRgAc', data, true);
};

// 기능 On/Off API
export const getFunctionOnOff = async data => {
  return await callMdpApi('/mydata-member/function-on-off', data, true);
};

// 마이데이터회원정보 조회API, API_MB_001
export const getMemberInfo = async () => {
  return await callMdpApi('/mydata-member/info', {}, true);
};

/**
 * [메인화면] 메인 통합자산 조회 API
 * @param {String} data 요청DATA
 * @returns
 */
export const mainInquirySync = async () => {
  return await callMdpApi({ url: '/mai/maiAsts', progress: false }, {});
};

/**
 * [메인화면] 자산 수집요청 API
 * @returns
 */
export const mainCollect = async () => {
  return await callMdpApi({ url: '/collect/main-collect', progress: false }, {}, true);
};

/**
 * [메인화면] 머니이용내역/적립금조회
 * @param {String} data 요청DATA
 * @returns
 */
export const selMnyUIz = async data => {
  return await callApi({ url: '/restapi/mny/base/selMnyUIz', progress: false }, data);
};

/**
 * [메인화면] 서비스가입정보조회(부동산) selSvJInf API
 * @param {Object} param 요청 param
 * @returns selSvJInf
 */
export const selSvJInf = async param => {
  return await callApi({ url: '/restapi/ast/rlest/main/selSvJInf', progress: false }, param);
};

/**
 * [메인화면] 자산 차량 정보 목록 조회 selListAstCarInf API
 * @param {Object} data 요청 data
 * @returns selListAstCarInf
 */
export const selListAstCarInf = async data => {
  return await callApi({ url: '/restapi/ast/car/mngt/selListAstCarInf', progress: false }, data);
};

/**
 * [메인화면] 실시간 평점 및 누적순위
 * @param {Object} 요청 data
 * @returns result
 */
export const selRtmCrdScoreMydata = async data => {
  return await callApi({ url: '/restapi/fnn/crdnice/selRtmCrdScore', progress: false }, data, true);
};

/**
 * [메인화면] NICE평가정보 신용조회이용여부조회
 * @returns {object}
 */
export const selNiceCrdInqrU = async () => {
  return await callApi({ url: '/restapi/fnn/crdmngt/selNiceCrdInqrU', progress: false });
};

/**
 * [메인화면] 계좌 조회
 * @returns {object}
 */
export const maiAc = async () => {
  return await callMdpApi({ url: '/mai/ac', progress: false }, {}, true);
};

/**
 * [메인화면] 소비 조회
 * @returns {object}
 */
export const maiCsm = async () => {
  return await callMdpApi({ url: '/mai/csm', progress: false }, {}, true);
};

/**
 * [메인화면] 투자 조회
 * @returns {object}
 */
export const maiIv = async () => {
  return await callMdpApi({ url: '/mai/iv', progress: false }, {}, true);
};

/**
 * [메인화면] 보험 조회
 * @returns {object}
 */
export const maiInsr = async () => {
  return await callMdpApi({ url: '/mai/insr', progress: false }, {}, true);
};

/**
 * [메인화면] 대출 조회
 * @returns {object}
 */
export const maiLn = async () => {
  return await callMdpApi({ url: '/mai/ln', progress: false }, {}, true);
};

/**
 * [메인화면] 연금 조회
 * @returns {object}
 */
export const maiPen = async () => {
  return await callMdpApi({ url: '/mai/pen', progress: false }, {}, true);
};

/**
 * [메인화면] 포인트/머니 조회
 * @returns {object}
 */
export const maiPmny = () => {
  return callMdpApi({ url: '/mai/p-mny', progress: false }, {}, true);
};

/**
 * [메인화면] 건강 조회
 * @returns {object}
 */
export const maiHealth = () => {
  return callMdpApi({ url: '/he/he-score', progress: false }, {}, true);
};

/**
 * [메인화면] "금융자산보기" 미션
 * @returns {object}
 */
export const prcsInternalMisnApi = async (context, data) => {
  return await callApi(
    { url: '/restapi/fvr/reward/mission/prcsInternalMisnApi', progress: false },
    data,
    false,
    false,
    context,
  );
};

/**
 * [메인화면] 기타자산 조회
 * @returns {object}
 */
export const maiEtcAsts = async () => {
  return await callMdpApi({ url: '/mai/etc-asts', progress: false }, {}, true);
};

/**
 * [메인화면] 자산변동 조회
 * @returns {object}
 */
export const maiAstsVslztn = async () => {
  return await callMdpApi({ url: '/mai/asts-vslztn', progress: false });
};

/**
 * [자산분석] 보유자산 조회
 * @returns {object}
 */
export const maiAstsSplctn = async () => {
  return await callMdpApi({ url: '/mai/maiAsts-splctn', progress: false });
};
